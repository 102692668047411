import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import { Layout, ConfigProvider } from 'antd';
import pt_BR from 'antd/es/locale/pt_BR';
import GlobalStyle from './styles/global';
import AppProvider from './hooks';
import Routes from './routes';
import NavBar from './components/NavBar';

const { Content } = Layout;

const App: React.FC = () => {
  return (
    <Router>
      <AppProvider>
        <ConfigProvider locale={pt_BR}>
          <GlobalStyle />
          <Layout>
            <NavBar />
            <Content
              className="site-layout"
              style={{ padding: '0 50px', marginTop: 64 }}
            >
              <div
                className="site-layout-background"
                style={{ padding: 24, marginTop: 20 }}
              >
                <Routes />
              </div>
            </Content>
          </Layout>
        </ConfigProvider>
      </AppProvider>
    </Router>
  );
};

export default App;

import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '../hooks/auth';

const HomeRoute: React.FC = () => {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="/shortener/new" />;
  }

  return <Navigate to="/signin" />;
};

export default HomeRoute;

import React from 'react';
import { Card, Result } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

const Redirect: React.FC = () => {
  return (
    <Card style={{ width: '100%' }}>
      <Result
        status="404"
        title="Página não encontrada!"
        icon={<SmileOutlined />}
      />
    </Card>
  );
};

export default Redirect;

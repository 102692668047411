import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '../hooks/auth';

interface Props {
  component: React.ComponentType;
  role?: string;
}

const PrivateRoute: React.FC<Props> = ({ component: RouteComponent, role }) => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/signin" />;
  }

  if (role && user.profile !== role) {
    return <Navigate to="/shortener/new" />;
  }

  return <RouteComponent />;
};

PrivateRoute.defaultProps = {
  role: undefined,
};

export default PrivateRoute;

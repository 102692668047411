import React, { useCallback, useEffect, useState } from 'react';
import { Table, Card, notification, Tooltip, Button, Form, Input } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { FormOutlined, SearchOutlined } from '@ant-design/icons';
import api from '../../../services/api';

interface User {
  id: string;
  name: string;
  email: string;
  profile: string;
  created_at: string;
  updated_at: string;
}

interface UsersResponseData {
  users: User[];
  count: number;
}

interface UsersFormData {
  search: string;
}

const UsersList: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>();
  const [loading, setLoading] = useState(false);
  const [pager, setPager] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const loadUsers = useCallback(async (page = 1, limit = 10, search = '') => {
    try {
      setLoading(true);
      const response = await api.get<UsersResponseData>(`/users`, {
        params: {
          page,
          limit,
          search,
        },
      });

      setUsers(response.data.users);
      setPager({
        current: page,
        pageSize: limit,
        total: response.data.count,
      });
    } catch (err) {
      notification.error({
        message: 'Erro!',
        description: 'Ocorreu um erro ao listar os usuários, tente novamente.',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const onFinish = useCallback(async (values: UsersFormData) => {
    await loadUsers(1, 10, values.search);
  }, []);

  useEffect(() => {
    loadUsers();
  }, []);

  const columns: ColumnsType<User> = [
    {
      key: 'name',
      title: 'Nome',
      dataIndex: 'name',
    },
    {
      key: 'email',
      title: 'E-mail',
      dataIndex: 'email',
    },
    {
      key: 'profile',
      title: 'Perfil',
      dataIndex: 'profile',
    },
    {
      key: 'acao',
      title: 'Ação',
      dataIndex: 'acao',
      align: 'center',
      render: (_, record) => (
        <Tooltip placement="top" title="Editar">
          <Button
            size="small"
            icon={<FormOutlined />}
            onClick={() => navigate(`/users/${record.id}`)}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <Card
      title="Cadastro de Usuários"
      extra={
        <Button
          type="dashed"
          htmlType="button"
          loading={loading}
          onClick={() => navigate('/users/new')}
        >
          Novo usuário
        </Button>
      }
      style={{ width: '100%' }}
    >
      <Form
        form={form}
        name="users"
        layout="inline"
        style={{ marginBottom: 20 }}
        onFinish={onFinish}
      >
        <Form.Item
          label="Nome/E-mail"
          name="search"
          style={{ width: '89.5%', margin: 0 }}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item style={{ width: '10%', margin: 0 }}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<SearchOutlined />}
            loading={loading}
          >
            Pesquisar
          </Button>
        </Form.Item>
      </Form>
      <Table<User>
        style={{ minWidth: 1024 }}
        bordered
        pagination={{
          ...pager,
          showTotal: (total) =>
            `Total de ${total} ${total > 1 ? 'itens' : 'item'}`,
          showSizeChanger: true,
        }}
        columns={columns}
        dataSource={users?.map((user) => ({
          ...user,
          key: user.id,
        }))}
        onChange={(pagination) => {
          loadUsers(
            pagination.current,
            pagination.pageSize,
            form.getFieldValue('search'),
          );
        }}
        loading={loading}
      />
    </Card>
  );
};

export default UsersList;

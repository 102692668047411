import React, { useCallback, useState, useEffect } from 'react';
import { Form, notification, Card } from 'antd';
import { useParams } from 'react-router-dom';
import api from '../../../services/api';
import UserForm from '../Form';

interface UserFormData {
  name: string;
  email: string;
  password: string;
  profile: string;
}

interface UserResponseData {
  name: string;
  email: string;
  profile: string;
  created_at: string;
  updated_at: string;
}

const UserUpdate: React.FC = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const loadUser = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get<UserResponseData>(`/users/${id}`);
      form.setFieldsValue({
        name: response.data.name,
        email: response.data.email,
        profile: response.data.profile === 'admin',
      });
    } catch (err) {
      notification.error({
        message: 'Erro!',
        description: 'Ocorreu um erro ao carregar o usuário, tente novamente.',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadUser();
  }, []);

  const onFinish = useCallback(async (values: UserFormData) => {
    try {
      setLoading(true);
      await api.put(`/users/${id}`, {
        ...values,
        profile: values.profile ? 'admin' : 'user',
      });

      form.setFieldsValue({
        password: null,
      });
      notification.success({
        message: 'Sucesso',
        description: 'Usuário salvo com sucesso.',
      });
    } catch (err) {
      notification.error({
        message: 'Erro!',
        description:
          'Ocorreu um erro ao salvar o usuário, e-mail já cadastrado ou tente novamente.',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Card title="Cadastro de Usuário" style={{ width: '100%' }}>
      <UserForm
        form={form}
        loading={loading}
        onFinish={onFinish}
        requiredPassword={false}
      />
    </Card>
  );
};

export default UserUpdate;

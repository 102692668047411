import React, { useCallback, useState } from 'react';
import {
  Form,
  Input,
  Button,
  notification,
  Card,
  Alert,
  Upload,
  message,
} from 'antd';
import {
  CopyOutlined,
  DownloadOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import api from '../../../services/api';

interface ShortenerFormData {
  original_url: string;
}

interface ShortenerResponseData {
  shortened_url: string;
}

interface CsvData {
  [key: string]: string;
}

const Shortener: React.FC = () => {
  const [form] = Form.useForm();
  const [shortenedUrl, setShortenedUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const downloadTemplateCsv = useCallback((): void => {
    const rows = [['original_url', 'shortened_url']];

    const csv = `data:text/csv;charset=utf-8,${rows
      .map((e) => e.join(','))
      .join('\n')}`;

    const encodedUri = encodeURI(csv);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'template_import_urls.csv');
    document.body.appendChild(link);

    link.click();
  }, []);

  const uploadFile = useCallback(async (options) => {
    const { onSuccess, onError, file } = options;
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append('file', file);

      const response = await api.post('/importer', formData);

      const delimiter = ',';
      const str = response.data;

      const headers = str.slice(0, str.indexOf('\n')).split(delimiter);
      const rows: string[] = str.slice(str.indexOf('\n') + 1).split('\n');

      const arr: CsvData[] = rows
        .map((row: string) => {
          const values = row.split(delimiter);
          const el: CsvData = headers.reduce(
            (object: CsvData, header: string, index: number) => {
              object[header] = values[index];
              return object;
            },
            {},
          );

          return {
            original_url: el.original_url,
            shortened_url: `${window.origin}/${el.shortened_url}`,
          };
        })
        .filter((item) => item.original_url && item.shortened_url);

      const headersCsv = Object.keys(arr[0]).join();
      const content = arr.map((r) => Object.values(r).join());
      const csvData = [headersCsv].concat(content).join('\n');

      const csv = new Blob([csvData], {
        type: 'text/csv;charset=utf-8;',
      });

      const encodedUri = window.URL.createObjectURL(csv);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'export_urls.csv');
      document.body.appendChild(link);

      link.click();

      onSuccess('Ok');
    } catch (err) {
      notification.error({
        message: 'Erro!',
        description: 'Ocorreu um erro ao importar, tente novamente.',
      });
      onError('Ocorreu um erro ao importar, tente novamente.');
    } finally {
      setLoading(false);
    }
  }, []);

  const onFinish = useCallback(async (values: ShortenerFormData) => {
    try {
      setLoading(true);
      const response = await api.post<ShortenerResponseData>(
        '/shortener',
        values,
      );

      setShortenedUrl(`${window.origin}/${response.data.shortened_url}`);
      form.resetFields();
    } catch (err) {
      notification.error({
        message: 'Erro!',
        description:
          'Ocorreu um erro ao criar um link encurtado, tente novamente.',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const copyToClipboard = useCallback((text: string) => {
    navigator.clipboard.writeText(text);
  }, []);

  return (
    <Card title="Encurtar URL" style={{ width: '100%' }}>
      {shortenedUrl && (
        <Alert
          style={{ marginBottom: 20 }}
          message={`Link encurtado: ${shortenedUrl}`}
          type="success"
          showIcon
          action={
            <Button
              size="small"
              type="text"
              icon={<CopyOutlined />}
              onClick={() => copyToClipboard(shortenedUrl)}
            />
          }
          closable
        />
      )}
      <Form form={form} name="shortener" onFinish={onFinish}>
        <Form.Item
          label="Cole o link aqui"
          name="original_url"
          rules={[
            { required: true, message: 'Por favor preencha a URL!' },
            {
              type: 'url',
              message: 'Este campo deve ser uma URL válida.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item style={{ textAlign: 'right' }}>
          <Button
            icon={<DownloadOutlined />}
            htmlType="button"
            style={{ marginRight: 5 }}
            loading={loading}
            onClick={downloadTemplateCsv}
          >
            Template
          </Button>
          <Upload
            showUploadList={false}
            beforeUpload={(file) => {
              const ext = file.name.split('.')[1] || '';

              const isCSV = ext === 'csv' || file.type === 'text/csv';
              if (!isCSV) {
                message.error(`${file.name} não é um arquivo CSV!`);
              }

              const isLargeSize = file.size / 1024 / 1024 < 1;
              if (!isLargeSize) {
                message.error('CSV maior que 1MB!');
              }
              return isCSV || Upload.LIST_IGNORE;
            }}
            customRequest={uploadFile}
          >
            <Button
              icon={<UploadOutlined />}
              htmlType="button"
              style={{ marginRight: 5 }}
              loading={loading}
            >
              Importar URLs
            </Button>
          </Upload>
          <Button type="primary" htmlType="submit" loading={loading}>
            Encurtar URL
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Shortener;

import React, { useCallback, useState, useEffect } from 'react';
import { Form, Input, Button, notification, Card } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../services/api';

interface ShortenerFormData {
  original_url: string;
}

interface ShortenerResponseData {
  id: string;
  original_url: string;
  shortened_url: string;
  created_at: string;
  updated_at: string;
}

const ShortenerUpdate: React.FC = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const loadShortener = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get<ShortenerResponseData>(`/shortener/${id}`);
      form.setFieldsValue({
        original_url: response.data.original_url,
        shortened_url: `${window.origin}/${response.data.shortened_url}`,
      });
    } catch (err) {
      notification.error({
        message: 'Erro!',
        description:
          'Ocorreu um erro ao carregar a URL encurtada, tente novamente.',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadShortener();
  }, []);

  const onFinish = useCallback(async (values: ShortenerFormData) => {
    try {
      setLoading(true);
      await api.put<ShortenerResponseData>(`/shortener/${id}`, values);

      notification.success({
        message: 'Sucesso',
        description: 'URL encurtada salva com sucesso.',
      });
    } catch (err) {
      notification.error({
        message: 'Erro!',
        description:
          'Ocorreu um erro ao atualizar a URL encurtada, ela já esta sendo usada ou tente novamente.',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Card title="Cadastro de URL" style={{ width: '100%' }}>
      <Form form={form} name="shortener" onFinish={onFinish}>
        <Form.Item label="URL encurtada" name="shortened_url">
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="URL original"
          name="original_url"
          rules={[
            { required: true, message: 'Por favor preencha a URL!' },
            {
              type: 'url',
              message: 'Este campo deve ser uma URL válida.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item style={{ textAlign: 'right' }}>
          <Button
            type="default"
            htmlType="button"
            loading={loading}
            onClick={() => navigate('/shortener')}
            style={{ marginRight: 5 }}
          >
            Voltar
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ShortenerUpdate;

import React, { useCallback, useState } from 'react';
import { Form, Input, Button, notification, Card } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(
    async (values: SignInFormData) => {
      try {
        setLoading(true);
        await signIn({
          email: values.email,
          password: values.password,
        });

        navigate('/shortener/new');
      } catch (err) {
        notification.error({
          message: 'Atenção!',
          description: 'Combinação incorreta de e-mail/senha.',
        });
      } finally {
        setLoading(false);
      }
    },
    [signIn, navigate],
  );

  return (
    <Card title="Autenticação" style={{ width: '100%' }}>
      <Form name="signin" onFinish={onFinish} autoComplete="off">
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              message: 'Por favor preencha o e-mail!',
              type: 'email',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Senha"
          name="password"
          rules={[{ required: true, message: 'Por favor preencha a senha!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Entrar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default SignIn;

import React, { useCallback, useEffect, useState } from 'react';
import { Card, Result } from 'antd';
import { useParams } from 'react-router-dom';
import { SmileOutlined } from '@ant-design/icons';

import api from '../../services/api';

interface RedirectResponseData {
  original_url: string;
}

const Redirect: React.FC = () => {
  const { shortenedUrl } = useParams();
  const [error, setError] = useState(false);

  const redirectUrl = useCallback(async (url: string) => {
    try {
      const response = await api.get<RedirectResponseData>(`/redirect/${url}`);

      window.location.href = response.data.original_url;
    } catch (err) {
      setError(true);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (shortenedUrl) {
        redirectUrl(shortenedUrl);
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [shortenedUrl]);

  return (
    <Card style={{ width: '100%' }}>
      <Result
        status={error ? '404' : 'info'}
        title={
          error
            ? 'Página não encontrada!'
            : 'Estamos redirecionando para página desejada...'
        }
        icon={<SmileOutlined />}
      />
    </Card>
  );
};

export default Redirect;

import React, { useCallback, useEffect, useState } from 'react';
import { Table, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import api from '../../../services/api';

interface ShortenerLog {
  id: string;
  url_shortener_id: string;
  description: string;
  responsible: string;
  created_at: string;
}

interface ShortenerLogResponseData {
  shortener_logs: ShortenerLog[];
  count: number;
}

interface ShortenerLogsListProps {
  urlShortenerId: string;
}

const ShortenerLogsList: React.FC<ShortenerLogsListProps> = ({
  urlShortenerId,
}) => {
  const [shortenerLogs, setShortenerLogs] = useState<ShortenerLog[]>();
  const [loading, setLoading] = useState(false);
  const [pager, setPager] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const loadShortenerLogs = useCallback(async (page = 1, limit = 10) => {
    try {
      setLoading(true);
      const response = await api.get<ShortenerLogResponseData>(
        `/shortener/${urlShortenerId}/logs`,
        {
          params: {
            page,
            limit,
          },
        },
      );

      setShortenerLogs(response.data.shortener_logs);
      setPager({
        current: page,
        pageSize: limit,
        total: response.data.count,
      });
    } catch (err) {
      notification.error({
        message: 'Erro!',
        description:
          'Ocorreu um erro ao listar o histórico da URL encurtada, tente novamente.',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadShortenerLogs();
  }, []);

  const columns: ColumnsType<ShortenerLog> = [
    {
      key: 'description',
      title: 'Alteração',
      dataIndex: 'description',
    },
    {
      key: 'responsible',
      title: 'Responsável',
      dataIndex: 'responsible',
    },
  ];

  return (
    <Table<ShortenerLog>
      style={{ marginTop: 20 }}
      bordered
      pagination={{
        ...pager,
        showTotal: (total) =>
          `Total de ${total} ${total > 1 ? 'itens' : 'item'}`,
        showSizeChanger: true,
      }}
      columns={columns}
      dataSource={shortenerLogs?.map((shortenerLog) => ({
        ...shortenerLog,
        key: shortenerLog.id,
      }))}
      onChange={(pagination) => {
        loadShortenerLogs(pagination.current, pagination.pageSize);
      }}
      loading={loading}
    />
  );
};

export default ShortenerLogsList;

import React, { useCallback, useState } from 'react';
import { Form, notification, Card } from 'antd';
import api from '../../../services/api';
import UserForm from '../Form';

interface UserFormData {
  name: string;
  email: string;
  password: string;
  profile: string;
}

const User: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(async (values: UserFormData) => {
    try {
      setLoading(true);
      await api.post('/users', {
        ...values,
        profile: values.profile ? 'admin' : 'user',
      });

      form.resetFields();
      notification.success({
        message: 'Sucesso',
        description: 'Usuário salvo com sucesso.',
      });
    } catch (err) {
      notification.error({
        message: 'Erro!',
        description:
          'Ocorreu um erro ao criar um usuário, e-mail já cadastrado ou tente novamente.',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Card title="Cadastro de Usuário" style={{ width: '100%' }}>
      <UserForm
        form={form}
        loading={loading}
        onFinish={onFinish}
        requiredPassword
      />
    </Card>
  );
};

export default User;

import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  Card,
  notification,
  Tooltip,
  Button,
  Input,
  Form,
  Drawer,
  DatePicker,
  Modal,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import {
  ExportOutlined,
  FormOutlined,
  HistoryOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { RangeValue } from 'rc-picker/lib/interface';
import api from '../../../services/api';
import ShortenerLogsList from '../Logs';

const { RangePicker } = DatePicker;

interface Shortener {
  id: string;
  original_url: string;
  shortened_url: string;
  created_at: string;
  updated_at: string;
}

interface ShortenersResponseData {
  shorteners: Shortener[];
  count: number;
}

interface ShortenerFormData {
  search: string;
}

interface CsvData {
  [key: string]: string;
}

const ShortenerList: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();
  const [shorteners, setShorteners] = useState<Shortener[]>();
  const [loading, setLoading] = useState(false);
  const [pager, setPager] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [visible, setVisible] = useState(false);

  const [dates, setDates] = useState<RangeValue<moment.Moment>>();
  const [hackValue, setHackValue] = useState<RangeValue<moment.Moment>>();
  const [value, setValue] = useState<RangeValue<moment.Moment>>();

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const configModal = (urlShortenerId: string) => ({
    title: 'Histórico de alterações',
    content: <ShortenerLogsList urlShortenerId={urlShortenerId} />,
    width: 1024,
    closable: true,
  });

  const loadShorteners = useCallback(
    async (page = 1, limit = 10, search = '') => {
      try {
        setLoading(true);
        const response = await api.get<ShortenersResponseData>(`/shortener`, {
          params: {
            page,
            limit,
            search,
          },
        });

        setShorteners(response.data.shorteners);
        setPager({
          current: page,
          pageSize: limit,
          total: response.data.count,
        });
      } catch (err) {
        notification.error({
          message: 'Erro!',
          description:
            'Ocorreu um erro ao listar as URLs encurtadas, tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const onFinish = useCallback(async (values: ShortenerFormData) => {
    await loadShorteners(1, 10, values.search);
  }, []);

  const onClick = useCallback(async () => {
    if (dates && Array.isArray(dates) && dates[0] && dates[1]) {
      try {
        setLoading(true);
        const response = await api.get(`/exporter`, {
          params: {
            initial_date: `${dates[0].year()}-01-01T00:00:00Z`,
            final_date: `${dates[1].year()}-12-31T23:59:00Z`,
          },
        });

        const delimiter = ',';
        const str = response.data;

        const headers = str.slice(0, str.indexOf('\n')).split(delimiter);
        const rows: string[] = str.slice(str.indexOf('\n') + 1).split('\n');

        const arr: CsvData[] = rows
          .map((row: string) => {
            const values = row.split(delimiter);
            const el: CsvData = headers.reduce(
              (object: CsvData, header: string, index: number) => {
                object[header] = values[index];
                return object;
              },
              {},
            );

            return {
              original_url: el.original_url,
              shortened_url: `${window.origin}/${el.shortened_url}`,
            };
          })
          .filter((item) => item.original_url && item.shortened_url);

        const headersCsv = Object.keys(arr[0]).join();
        const content = arr.map((r) => Object.values(r).join());
        const csvData = [headersCsv].concat(content).join('\n');

        const csv = new Blob([csvData], {
          type: 'text/csv;charset=utf-8;',
        });

        const encodedUri = window.URL.createObjectURL(csv);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'export_urls.csv');
        document.body.appendChild(link);

        link.click();
      } catch (err) {
        notification.error({
          message: 'Erro!',
          description:
            'Ocorreu um erro ao exportar as URLs encurtadas, tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    }
  }, [dates]);

  useEffect(() => {
    loadShorteners();
  }, []);

  const columns: ColumnsType<Shortener> = [
    {
      key: 'original_url',
      title: 'URL original',
      dataIndex: 'original_url',
    },
    {
      key: 'shortened_url',
      title: 'URL encurtada',
      dataIndex: 'shortened_url',
    },
    {
      key: 'acao',
      title: 'Ação',
      dataIndex: 'acao',
      align: 'center',
      render: (_, record) => (
        <>
          <Tooltip placement="top" title="Editar">
            <Button
              size="small"
              icon={<FormOutlined />}
              onClick={() => navigate(`/shortener/${record.id}`)}
              style={{ marginRight: 5 }}
            />
          </Tooltip>
          <Tooltip placement="top" title="Histórico">
            <Button
              size="small"
              icon={<HistoryOutlined />}
              onClick={() => modal.info(configModal(record.id))}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const disabledDate = (current: moment.Moment): boolean => {
    if (!dates) {
      return false;
    }

    const tooLate = !!(dates[0] && current.diff(dates[0], 'years') > 4);
    const tooEarly = !!(dates[1] && dates[1].diff(current, 'years') > 4);
    return tooEarly || tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(undefined);
    }
  };

  return (
    <Card
      title="Cadastro de URLs"
      style={{ width: '100%' }}
      extra={
        <Button
          type="dashed"
          htmlType="button"
          loading={loading}
          onClick={showDrawer}
        >
          Exportar URLs
        </Button>
      }
    >
      <Drawer
        title="Selecione o periodo"
        placement="right"
        onClose={onClose}
        visible={visible}
        style={{ textAlign: 'center' }}
      >
        <RangePicker
          picker="year"
          value={hackValue || value}
          disabledDate={disabledDate}
          onCalendarChange={(val) => setDates(val)}
          onChange={(val) => setValue(val)}
          onOpenChange={onOpenChange}
        />
        <div style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<ExportOutlined />}
            loading={loading}
            style={{ marginTop: 10, marginRight: 10 }}
            onClick={onClick}
          >
            Exportar
          </Button>
        </div>
      </Drawer>
      <Form
        form={form}
        name="shortener"
        layout="inline"
        style={{ marginBottom: 20 }}
        onFinish={onFinish}
      >
        <Form.Item
          label="URL"
          name="search"
          style={{ width: '89.5%', margin: 0 }}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item style={{ width: '10%', margin: 0 }}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<SearchOutlined />}
            loading={loading}
          >
            Pesquisar
          </Button>
        </Form.Item>
      </Form>
      <Table<Shortener>
        style={{ minWidth: 1024 }}
        bordered
        pagination={{
          ...pager,
          showTotal: (total) =>
            `Total de ${total} ${total > 1 ? 'itens' : 'item'}`,
          showSizeChanger: true,
        }}
        columns={columns}
        dataSource={shorteners?.map((shortener) => ({
          ...shortener,
          key: shortener.id,
          shortened_url: `${window.origin}/${shortener.shortened_url}`,
        }))}
        onChange={(pagination) => {
          loadShorteners(
            pagination.current,
            pagination.pageSize,
            form.getFieldValue('search'),
          );
        }}
        loading={loading}
      />
      {contextHolder}
    </Card>
  );
};

export default ShortenerList;

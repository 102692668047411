import React from 'react';
import { Form, Input, Button, Checkbox, FormInstance } from 'antd';
import { Callbacks } from 'rc-field-form/lib/interface';
import { useNavigate } from 'react-router-dom';

interface UserFormData {
  form: FormInstance;
  loading: boolean;
  onFinish: Callbacks['onFinish'];
  requiredPassword: boolean;
}

const UserForm: React.FC<UserFormData> = ({
  form,
  loading,
  onFinish,
  requiredPassword,
}) => {
  const navigate = useNavigate();
  return (
    <Form form={form} name="user" onFinish={onFinish} labelCol={{ span: 2 }}>
      <Form.Item
        label="Nome"
        name="name"
        rules={[{ required: true, message: 'Por favor preencha o nome!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="E-mail"
        name="email"
        rules={[
          {
            required: true,
            message: 'Por favor preencha o e-mail!',
            type: 'email',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Senha"
        name="password"
        rules={[
          {
            required: requiredPassword,
            message: requiredPassword ? 'Por favor preencha a senha!' : '',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="profile"
        valuePropName="checked"
        style={{ textAlign: 'right' }}
      >
        <Checkbox>Administrador</Checkbox>
      </Form.Item>
      <Form.Item style={{ textAlign: 'right' }}>
        <Button
          type="default"
          htmlType="button"
          loading={loading}
          onClick={() => navigate('/users')}
          style={{ marginRight: 5 }}
        >
          Voltar
        </Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserForm;

import React from 'react';
import { Routes as Switch, Route } from 'react-router-dom';

import SignIn from '../pages/SignIn';
import Redirect from '../pages/Redirect';
import User from '../pages/Users/New';
import UsersList from '../pages/Users/List';
import UserUpdate from '../pages/Users/Update';
import Shortener from '../pages/Shortener/New';
import ShortenerList from '../pages/Shortener/List';
import ShortenerUpdate from '../pages/Shortener/Update';
import NotFound from '../pages/NotFound';
import SignInRoute from './SignInRoute';
import PrivateRoute from './PrivateRoute';
import HomeRoute from './HomeRoute';

const Routes: React.FC = () => (
  <Switch>
    <Route path="*" element={<NotFound />} />
    <Route path="/" element={<HomeRoute />} />
    <Route path="/:shortenedUrl" element={<Redirect />} />
    <Route path="/signin" element={<SignInRoute component={SignIn} />} />
    <Route
      path="shortener/new"
      element={<PrivateRoute component={Shortener} />}
    />
    <Route
      path="shortener"
      element={<PrivateRoute component={ShortenerList} />}
    />
    <Route
      path="shortener/:id"
      element={<PrivateRoute component={ShortenerUpdate} />}
    />
    <Route
      path="users/new"
      element={<PrivateRoute component={User} role="admin" />}
    />
    <Route
      path="users"
      element={<PrivateRoute component={UsersList} role="admin" />}
    />
    <Route
      path="users/:id"
      element={<PrivateRoute component={UserUpdate} role="admin" />}
    />
  </Switch>
);

export default Routes;

import React from 'react';
import { Layout, Menu, Dropdown, Button } from 'antd';
import { DownOutlined, LogoutOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

const { Header } = Layout;

const NavBar: React.FC = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/shortener">Urls encurtadas</Link>
      </Menu.Item>
      {user && user.profile === 'admin' && (
        <Menu.Item key="2">
          <Link to="/users">Usuários</Link>
        </Menu.Item>
      )}
    </Menu>
  );

  return user ? (
    <Header
      style={{
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        textAlign: 'right',
        backgroundColor: '#fff',
      }}
    >
      <Button
        style={{ color: 'rgba(0, 0, 0, 0.85)' }}
        className="ant-dropdown-link"
        onClick={() => navigate('/shortener/new')}
        type="link"
      >
        Encurtar URL
      </Button>
      <Dropdown overlay={menu} trigger={['click']}>
        <Button
          style={{ color: 'rgba(0, 0, 0, 0.85)' }}
          className="ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
          type="link"
        >
          Cadastros <DownOutlined />
        </Button>
      </Dropdown>
      <Button
        style={{ color: 'rgba(0, 0, 0, 0.85)' }}
        className="ant-dropdown-link"
        onClick={signOut}
        type="link"
      >
        Sair <LogoutOutlined />
      </Button>
    </Header>
  ) : (
    <div />
  );
};

export default NavBar;

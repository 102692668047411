import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '../hooks/auth';

interface Props {
  component: React.ComponentType;
}

const SignInRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="/shortener/new" />;
  }

  return <RouteComponent />;
};

export default SignInRoute;
